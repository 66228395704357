import { useRef, useCallback, useLayoutEffect } from 'react';

/**
 * ⚠️ NOT A REPLACEMENT FOR `useEffectEvent`
 *
 * It's a basic implementation of the `useEffectEvent` hook, currently only available in react 18 experimental.
 *
 * The callback from the `useEffectEvent` should be used inside a `useEffect`.
 *
 * You should only use it in very specific case: When your `useEffect` depends on some dependencies but you don't want to rerun on each change of those dependencies.
 *
 * https://react.dev/learn/separating-events-from-effects#reading-latest-props-and-state-with-effect-events
 */
export default function useEffectEvent<T extends CallableFunction>(func: T): T {
  const ref = useRef<T>(func);

  useLayoutEffect(() => {
    ref.current = func;
  });

  return (useCallback(
    (...params) => ref.current(...params),
    [],
  ) as unknown) as T;
}
