import {
  Appointment,
  CardHcd,
  ConsultationReason,
} from '@maiia/model/generated/model/api-patient/api-patient';
import dayjs from 'dayjs';
import { HydratedAppointment } from '@/src/hooks/appointments/useAppointments';

export type AppointmentVariant =
  | 'CANCELLED_BY_PATIENT'
  | 'CANCELLED_BY_PRACTITIONER'
  | 'JUST_MODIFIED'
  | 'ONGOING'
  | 'PAST'
  | 'CONFIRMED'
  | 'MISSED'
  | 'DELAYED';

export type CurrentAppointment = {
  id: string | null;
  mode: 'UPCOMING' | 'PAST';
};

export const toHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours > 0 ? `${hours} h` : ''}${
    minutes > 0 ? ` ${minutes} min` : ''
  }`;
};

export const getStringDelay = (appointment?: HydratedAppointment) => {
  return appointment?.proDelay
    ? toHoursAndMinutes(appointment.proDelay)
    : undefined;
};

export const getIsDelayed = (appointment?: HydratedAppointment) => {
  // 30/08/23: appointmentStatus "DELAYED" not usable
  return !!(appointment?.appointmentStatus === 'NONE' && appointment?.proDelay);
};

export const getAppointmentVariant = (
  appointment: Appointment,
  query?: { modified?: string },
): AppointmentVariant => {
  const { appointmentStatus, cancelReason, isTeleconsultation } = appointment;

  // Cancelled variants
  if (appointmentStatus === 'CANCELLED') {
    return cancelReason?.includes('PATIENT')
      ? 'CANCELLED_BY_PATIENT'
      : 'CANCELLED_BY_PRACTITIONER';
  }

  // Missed variant
  if (
    appointmentStatus === 'MISSED' ||
    appointmentStatus === 'NO_SHOW' ||
    (appointment.videoSessionInformation?.currentStatus as string) === 'MISSED'
  ) {
    return 'MISSED';
  }

  // Just modified variant
  if (
    appointmentStatus === 'NONE' &&
    query?.modified === 'success' &&
    dayjs().diff(dayjs(appointment.updateDate), 'minute') < 1
  ) {
    return 'JUST_MODIFIED';
  }

  // For TLC, patient can start session 15 minutes before 'START TIME' to 1 hour after 'START TIME'
  const startTime = isTeleconsultation
    ? dayjs(appointment.startDate).subtract(15, 'minute')
    : dayjs(appointment.startDate);
  const endTime = isTeleconsultation
    ? dayjs(appointment.startDate).add(1, 'hour')
    : dayjs(appointment.endDate);

  // Past variant
  if (
    dayjs().isAfter(endTime) ||
    appointmentStatus === 'COMPLETED' ||
    (appointment.videoSessionInformation?.currentStatus as string) ===
      'FINISHED'
  ) {
    return 'PAST';
  }

  // Ongoing variant
  if (
    dayjs().isBetween(startTime, endTime) &&
    ['NONE', 'IN_PROGRESS', 'PENDING'].includes(appointmentStatus as string)
  ) {
    return 'ONGOING';
  }

  // Delayed
  if (getIsDelayed(appointment)) {
    return 'DELAYED';
  }

  // Default variant
  return 'CONFIRMED';
};

export const getTimeTillAppointment = (
  appointment: Appointment,
  t,
  // "full" to prefix with "in"/"dans"
  full = false,
): string | null => {
  const startDateWithProDelay = dayjs(appointment.startDate).add(
    appointment.proDelay || 0,
    'minute',
  );
  const now = dayjs();

  const time = {
    value: 0,
    unit: '',
  };

  const variant = getAppointmentVariant(appointment);

  switch (true) {
    case variant.includes('CANCELLED'):
      return t('canceled');
    case variant === 'ONGOING':
      return t('ongoing');
    case variant === 'PAST':
      return t('ended');
    case dayjs(startDateWithProDelay).diff(now, 'd') >= 1:
      time.value = dayjs(startDateWithProDelay).diff(now, 'd');
      time.unit = 'day';
      break;
    case dayjs(startDateWithProDelay).diff(now, 'h') >= 1:
      time.value = dayjs(startDateWithProDelay).diff(now, 'h');
      time.unit = 'hour';
      break;
    case dayjs(startDateWithProDelay).diff(now, 'm') >= 1:
      time.value = dayjs(startDateWithProDelay).diff(now, 'm');
      time.unit = 'minute';
      break;
    case variant.includes('MISSED'):
      return t('missed');
    default:
      return null;
  }

  return `${full ? t('in') : ''}${time.value} ${t(time.unit)}${
    time.value > 1 ? 's' : ''
  }`;
};

export const getDisplayNameForAppointment = (
  appointment: HydratedAppointment,
): string => {
  // In case of appointment, the type of cardHcd is only practitioner
  // If practitioner is hidden (isVisibleOnline = false), we show the name of center instead
  const { cardHcd } = appointment;
  if (!cardHcd) return '';
  return cardHcd.settings?.isVisibleOnline
    ? `${cardHcd.professional?.firstName ?? ''} ${cardHcd.professional
        ?.lastName ?? ''}`
    : cardHcd.center?.name ?? '';
};

export const isValidTemporaryAppointment = (
  appointment: HydratedAppointment & { _timestamp?: string },
): boolean => {
  if (!appointment?.cardHcd) return false;
  const isValidStartDate = dayjs(appointment?.startDate).isAfter(new Date());
  const isValidTimestamp =
    Math.abs(dayjs(appointment?._timestamp).diff(new Date(), 'hours', true)) <=
    1;
  return isValidStartDate && isValidTimestamp;
};

export type TimeSlot = {
  centerId: string;
  color?: string;
  consultationReasons?: Array<ConsultationReason>;
  creationDate: string;
  endDateTime: string;
  id: string;
  isPunctual?: boolean;
  isVisibleOnline?: boolean;
  name?: string;
  percentageNewPatient?: number;
  practitionerId: string;
  startDateTime: string;
  timeSlotTemplateId?: string;
  updateDate: string;
  weekId?: string;
  weekTemplateCycleId?: string;
  weekTemplateId?: string;
};

export type AppointmentWithCardHcd = Appointment & { cardHcd: CardHcd };
