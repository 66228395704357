import * as React from 'react';
import {
  IconFacebookSquare,
  IconInstagramSquare,
  VectorBadgeAppStore,
  VectorBadgePlayStore,
} from '@maiia/design-system';
import { APP_ROUTES, MAP_PHARMARCIES_URL } from '@/src/constants';

export const specialitiesFooter = {
  listOne: t => [
    {
      title: t('footer__general_practitioner'),
      url: '/medecin-generaliste/75000-PARIS',
      id: 'medecin-generaliste',
    },
    {
      title: t('footer__masseur_physiotherapist'),
      url: '/masseur-kinesitherapeute/75000-PARIS',
      id: 'masseur-kinesitherapeute',
    },
    {
      title: t('footer__male_nurse'),
      url: '/infirmier/75000-PARIS',
      id: 'infirmier',
    },
    {
      title: t('footer__midwife'),
      url: '/sage-femme/75000-PARIS',
      id: 'sage-femme',
    },
    {
      title: t('footer__pharmacist'),
      url: '/pharmacien/75000-PARIS',
      id: 'pharmacien',
    },
    {
      title: t('footer__pedicure_podiatrist'),
      url: '/pedicure-podologue/75000-PARIS',
      id: 'pedicure-podologue',
    },
    {
      title: t('footer__psychiatrist'),
      url: '/psychiatre/75000-PARIS',
      id: 'psychiatre',
    },
    {
      title: t('footer__cardiologist'),
      url: '/cardiologue/75000-PARIS',
      id: 'cardiologue',
    },
  ],
  listTwo: t => [
    {
      title: t('footer__orthoptist'),
      url: '/orthoptiste/75000-PARIS',
      id: 'orthoptiste',
    },
    {
      title: t('footer__osteopath'),
      url: '/osteopathe/75000-PARIS',
      id: 'osteopathe',
    },
  ],
  listAll: t => [
    {
      title: t('footer__all_specialties'),
      url: APP_ROUTES.SPECIALITIES.INDEX,
      id: 'all-specialties',
    },
    {
      title: t('footer__all_our_practitioners'),
      url: APP_ROUTES.PRACTITIONERS.INDEX,
      id: 'all-practitioners',
    },
    {
      title: t('footer__all_our_pharmacies'),
      url: APP_ROUTES.PHARMACIES.INDEX,
      id: 'all-pharmacies',
    },
  ],
};

export const informationsAndArticlesFooter = t => [
  {
    title: t('footer__our_articles_teleconsultation'),
    url: '/teleconsultation/article',
    id: 'our-articles-teleconsultation',
  },
  {
    title: t('footer__our_articles_physiotherapist'),
    url: '/masseur-kinesitherapeute/article',
    id: 'our-articles-physio',
  },
  {
    title: t('footer__our_articles_generalist'),
    url: '/medecin-generaliste/article',
    id: 'our-articles-generalist',
  },
  {
    title: t('footer__our_articles_pharmacy'),
    url: '/pharmacie/article',
    id: 'our-articles-pharmacy',
  },
  {
    title: t('footer__bookstore'),
    url: 'https://www.cegedim.fr/presse/filiales/Pages/press-room-maiia.aspx',
    id: 'presse',
    type: 'external',
  },
  {
    title: t('footer__securing_your_health_data'),
    url: 'https://news.maiia.com/protection-securite-donnees-sante/actualites/',
    type: 'external',
    id: 'pro-sec-donne',
  },
];

export const aboutMaiiaFooter = t => [
  {
    title: t('footer__who_are_we'),
    url: '/qui-sommes-nous',
    id: 'qui-sommes-nous',
  },
  {
    title: t('footer__legal_notice_and_tcs'),
    url: '/mentions-legales',
    id: 'cgu',
  },
  {
    title: t('manage_cookies'),
    url: '#',
    id: 'cookies-management',
    onClick: event => {
      event.preventDefault();
      (window as any)?.tarteaucitron?.userInterface?.openPanel?.();
    },
  },
  {
    title: t('footer__privacy_policy'),
    url: '/donnees-personnelles',
    id: 'charte-confidentialité',
  },
];

export const socialFooter = t => [
  {
    title: t('follow-us-on-platform', { platform: t('facebook') }),
    url: 'https://www.facebook.com/maiia.patients',
    id: 'facebook',
    icon: <IconFacebookSquare alt={t('facebook')} aria-label={t('facebook')} />,
  },
  {
    title: t('follow-us-on-platform', { platform: t('instagram') }),
    url: 'https://www.instagram.com/maiia.patient/',
    id: 'instagram',
    icon: (
      <IconInstagramSquare alt={t('instagram')} aria-label={t('instagram')} />
    ),
  },
];

export const applicationFooter = t => [
  {
    title: t('download_on_the_app_store'),
    url: 'https://apps.apple.com/fr/app/maiia/id1489590732',
    id: 'app-store',
    icon: (
      <VectorBadgeAppStore alt={t('app-store')} aria-label={t('app-store')} />
    ),
  },
  {
    title: t('get_it_on_google_play'),
    url: 'https://maiiaapp.dre.agconnect.link/6dZl',
    id: 'play-store',
    icon: (
      <VectorBadgePlayStore
        alt={t('google-play')}
        aria-label={t('google-play')}
      />
    ),
  },
];

export const dynamicFooterDrugs = t => [
  {
    title: t('footer_all-our-medications'),
    url: APP_ROUTES.MEDICAMENTS.INDEX,
    id: 'medicaments',
  },
];

export const dynamicFooterTlc = {
  listOne: t => [
    {
      title: t('footer__learn_more_about_teleconsultation'),
      url: APP_ROUTES.TLC.BASE_URL,
      id: 'en-savoir-plus-sur-la-teleconsultation',
    },
    {
      title: t('footer__map_of_pharmacies'),
      url: MAP_PHARMARCIES_URL,
      id: 'tlc-pharma',
      type: 'external',
    },
  ],
  listTwo: t => [
    {
      title: t('footer__all_articles_on_teleconsultation'),
      url: '/teleconsultation/article',
      id: 'all_articles_on_teleconsultation',
    },
  ],
};
