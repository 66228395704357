import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websocketActions, serialActions } from '@docavenue/core';
import { useCurrentUser } from '@docavenue/components';
import { Portal } from '@mui/material';
import dynamic from 'next/dynamic';
import { VideoSession } from '@maiia/model/generated/model/api-patient/api-patient';
import { getListMediaDevices } from '@maiia/pat-shared';
import { useRouter } from 'next/router';
import { isCompleted } from '@/components/molecules/WithAuth/functions';
import OpenTokContext from './context';
import config from '../../../src/config';
import { videoSessionsActions } from '@/src/actions';
import { getDefaultDevice, getDetailVideoDevices, Device } from './functions';
import { useTranslation } from '@/src/i18n';
import { usePrevious } from '@/src/hooks';
import { TLC_UPLOADING_DOCUMENT } from '@/src/constants';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import { checkVideoStatusIs } from './utils';
import useEffectEvent from './useEffectEvent';

const OpenTokCommon = dynamic(() => import('./OpenTokCommon'), { ssr: false });

type Props = {
  children: React.ReactNode;
};

const styles = {
  container: {
    display: 'none',
  },
};

const isEmptyObject = (obj: object) => {
  // eslint-disable-next-line guard-for-in
  for (const _ in obj) {
    return false;
  }
  return true;
};

const videoSources = [
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/SubaruOutbackOnStreetAndDirt.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4',
];

const once = (fn: () => void): (() => void) => {
  let called = false;
  return () => {
    if (!called) {
      called = true;
      fn();
    }
  };
};

const fakeCamera = () => {
  const originalEnumerateDevices = navigator.mediaDevices.enumerateDevices;
  const originalGetUserMedia = navigator.mediaDevices.getUserMedia;

  const video = document.createElement('video');
  let isReady = false;

  video.crossOrigin = 'anonymous';
  video.src = videoSources[Math.floor(Math.random() * videoSources.length)];
  video.muted = true;
  video.loop = true;
  video.oncanplay = once(() => {
    isReady = true;
  });

  if (!(window as any).fakedDevices) {
    navigator.mediaDevices.enumerateDevices = async () => {
      const devices = await originalEnumerateDevices.call(
        navigator.mediaDevices,
      );
      const fakeCameraDevice = {
        deviceId: 'fake-camera',
        kind: 'videoinput',
        label: 'Fake Camera',
        groupId: '',
      };
      devices.push(fakeCameraDevice as any);
      return devices;
    };

    navigator.mediaDevices.getUserMedia = async (
      constraints?: MediaStreamConstraints,
    ) => {
      if (
        isReady &&
        constraints?.video &&
        ((constraints.video as any)?.deviceId?.exact === 'fake-camera' ||
          (constraints.video as any)?.deviceId === 'fake-camera')
      ) {
        if (video.paused) video.play();
        return (video as any).captureStream() as MediaStream;
      }
      return originalGetUserMedia.call(navigator.mediaDevices, constraints);
    };
    (window as any).fakedDevices = true;
  }
  return () => {
    navigator.mediaDevices.enumerateDevices = originalEnumerateDevices;
    navigator.mediaDevices.getUserMedia = originalGetUserMedia;
    (window as any).fakedDevices = false;
  };
};

const videoSessionSelector = state => state?.videoSessions?.item;

// connection data format key=value;...
const parseConnectionData = (data: string): Record<string, string> =>
  data.split(';').reduce((curr, item) => {
    const [key, value] = item.split('=');
    // eslint-disable-next-line no-param-reassign
    curr[key] = value;
    return curr;
  }, {});

const OpenTokSession = ({ children }: Props) => {
  const router = useRouter();
  const prevPathname = usePrevious(router.pathname);
  const { t } = useTranslation();
  const apiKey = config.get('TOKBOX_API_KEY');
  const [streams, setStreams] = React.useState<OT.Stream[]>([]);
  const [isOpenTokConnected, setIsOpenTokConnected] = React.useState(false);
  const sessionHelper = React.useRef<any>(null);
  const videoSession: VideoSession = useSelector(videoSessionSelector);
  const dispatch = useDispatch();
  const session = videoSession?.tokboxInformation?.session;
  const tokenPat = videoSession?.tokboxInformation?.tokenPat;
  const videoSessionStatus = videoSession?.videoSessionStatus;
  const practitionerId = videoSession?.practitionerId;
  const centerId = videoSession?.centerId;
  const currentUser = useCurrentUser<PatientUser>();
  const patientId = videoSession?.patientId;
  const showMiniOpenTok = router.pathname !== '/tlc/session/[id]';
  const [isReady, setIsReady] = React.useState(false);
  const showVideoSession =
    isReady &&
    checkVideoStatusIs(videoSessionStatus, [
      'WAITING',
      'PENDING',
      'STARTED',
      'REFUSED',
    ]);

  // State of audio, video devices
  const [audioDevices, setAudioDevices] = React.useState<Device[]>([]);
  const [videoDevices, setVideoDevices] = React.useState<Device[]>([]);

  // State of current audio, video device
  const [audioSource, setAudioSource] = React.useState({ deviceId: '' });
  const [videoSource, setVideoSource] = React.useState('');

  // State of publishAudio, publishVideo
  const [publishAudio, setPublishAudio] = React.useState(true);
  const [publishVideo, setPublishVideo] = React.useState(true);

  // If Pro has opened the picture / document viewer
  const [isProInDocumentViewer, setProIsInDocumentViewer] = React.useState(
    false,
  );

  const [
    videoDevicesByFacingMode,
    setVideoDevicesByFacingMode,
  ] = React.useState({});
  // Switch camera only display when having 2 camera with facingMode user and environment
  const [switchableCamera, setSwitchableCamera] = React.useState(false);

  // default mirror is true for camera with facingMode is user, if have no info about facingMode of camera, we set it to be `user`
  const [facingMode, setFacingMode] = React.useState('user');
  const [cameraMirror, setCameraMirror] = React.useState(true);

  // check has already connected from another devices
  const [isAlreadyConnected, setIsAlreadyConnected] = React.useState(false);

  const switchFacingMode = React.useCallback(() => {
    setFacingMode(prevFacingMode => {
      const newVal = prevFacingMode === 'user' ? 'environment' : 'user';
      setVideoSource(videoDevicesByFacingMode[newVal]);
      // Set default mirror for facingMode user
      setCameraMirror(newVal === 'user');
      return newVal;
    });
  }, [videoDevicesByFacingMode]);

  const toggleCameraMirrorMode = React.useCallback(() => {
    setCameraMirror(prevVal => !prevVal);
  }, []);

  // Container for keep opentok DOM element
  const container = React.useRef<HTMLDivElement | null>(null);
  const parentContainer = React.useRef<HTMLDivElement | null>(null);

  const _setAudioSource = React.useCallback(event => {
    setAudioSource(event);
    if (sessionHelper.current?.session) {
      sessionHelper.current.session.signal({
        type: 'deviceChanged',
        data: JSON.stringify({ audio: event.deviceId }),
      });
    }
  }, []);

  const _setVideoSource = React.useCallback(source => {
    setVideoSource(source);
    if (sessionHelper.current?.session) {
      sessionHelper.current.session.signal({
        type: 'deviceChanged',
        data: JSON.stringify({ video: source }),
      });
    }
  }, []);

  const setContainer = React.useCallback(node => {
    if (!node) return;
    container.current = node;
    parentContainer.current = node.parentElement;
  }, []);

  const onConnectionCreated = useEffectEvent(event => {
    const { connection } = event;
    // Trick, self-connection always comes last, so I'll check the ones that came before to see if I'm connected
    if (
      event.connection.connectionId ===
      sessionHelper?.current?.session?.connection.connectionId
    ) {
      if (isAlreadyConnected) {
        // eslint-disable-next-line no-console
        console.log('The user is already connected, disconnect');
        sessionHelper?.current?.session.disconnect();
      }
      return;
    }
    const data = parseConnectionData(connection.data);
    if (data?.uid === patientId) {
      setIsAlreadyConnected(true);
    }
  });

  const openTokEventHandlers = React.useMemo(
    () => ({
      connectionCreated: onConnectionCreated,
      'signal:proDocumentsOpened': () => {
        setProIsInDocumentViewer(true);
      },
      'signal:proDocumentsClosed': () => {
        setProIsInDocumentViewer(false);
      },
      signal: e => {
        // eslint-disable-next-line no-console
        console.log(e);
        if (!e?.data) return;
        const data = JSON.parse(e.data);
        if (data?.name === TLC_UPLOADING_DOCUMENT && data?.pro) {
          setProIsInDocumentViewer(data?.value);
        }
      },
      'signal:setDevice': e => {
        const data = JSON.parse(e.data);
        if (data.audio) {
          setAudioSource({ deviceId: data.audio });
          sessionHelper.current.session.signal({
            type: 'deviceChanged',
            data: JSON.stringify({ audio: data.audio }),
          });
        } else if (data.video) {
          setVideoSource(data.video);
          sessionHelper.current.session.signal({
            type: 'deviceChanged',
            data: JSON.stringify({ video: data.video }),
          });
        }
      },
      'signal:getDevices': async () => {
        const sendListDevices = async () => {
          const {
            audioDevices: _audioDevices,
            videoDevices: _videoDevices,
          } = await getListMediaDevices(t);
          // Update list devices
          setAudioDevices(_audioDevices);
          setVideoDevices(_videoDevices);

          // Send to prat
          const data = JSON.stringify({
            audios: _audioDevices,
            videos: _videoDevices,
          });
          sessionHelper.current?.session?.signal({ type: 'listDevices', data });
        };
        await sendListDevices();
        navigator.mediaDevices.ondevicechange = sendListDevices;
      },
      sessionConnected: () => {
        setIsOpenTokConnected(true);
        /**
         * Reset some values before the video session starts
         *
         * As we are in a React context, it is useful because
         * the context would use previous values if we already
         * did a TLC in the same browser session
         *  */
        if (checkVideoStatusIs(videoSessionStatus, ['WAITING', 'PENDING'])) {
          setPublishVideo(true);
          setPublishAudio(true);
        }
        // reset document state
        sessionHelper.current?.session?.signal({
          type: 'patDocumentsClosed',
        });
      },
      sessionDisconnected: () => {
        setIsOpenTokConnected(false);
        setProIsInDocumentViewer(false);
      },
      exception: (e: any) => {
        // eslint-disable-next-line no-console
        console.log('opentok', e.message);
      },
    }),
    [],
  );

  // Effect to get audio, video devices when videoStatus is STARTED
  React.useEffect(() => {
    if (videoSessionStatus === 'PENDING') {
      // When a new videoSession starts, we want to make sure to select the
      // default devices : MSUP-1360
      if (videoDevices.length > 0) {
        const defaultVideoDevice = getDefaultDevice(videoDevices);
        if (defaultVideoDevice) setVideoSource(defaultVideoDevice.id);
      }
      if (audioDevices.length > 0) {
        const defaultAudioDevice = getDefaultDevice(audioDevices);
        if (defaultAudioDevice)
          setAudioSource({ deviceId: defaultAudioDevice.id });
      }
    }
    if (videoSessionStatus === 'STARTED') {
      const updateListDevices = async () => {
        const {
          audioDevices: _audioDevices,
          videoDevices: _videoDevices,
        } = await getListMediaDevices(t);

        // Update list devices
        setAudioDevices(_audioDevices);
        setVideoDevices(_videoDevices);
      };
      updateListDevices();
    }
  }, [videoSessionStatus]);

  // Effect to handle tok connection
  const isVideoSessionStatusValid = checkVideoStatusIs(videoSessionStatus, [
    'WAITING',
    'STARTED',
    'PENDING',
  ]);
  const isPathnameValid = ![
    '/tlc/[center-id]/[practitioner-id]',
    '/download-app',
  ].includes(router.pathname);
  React.useEffect(() => {
    if (
      !(
        apiKey &&
        session &&
        tokenPat &&
        isVideoSessionStatusValid &&
        isPathnameValid
      )
    )
      return;
    const bc = new BroadcastChannel('OpenTokSession');
    let onBCMessage: (msg: any) => void = () => {};
    const isAnotherTabCheckPromise = new Promise(resolve => {
      onBCMessage = msg => {
        if (msg.data?.id === session) {
          if (msg.data?.type === 'check-another-tab')
            bc.postMessage({
              type: 'already-another-tab',
              id: session,
            });
          else if (msg.data?.type === 'already-another-tab') resolve(true);
        }
      };
      bc.addEventListener('message', onBCMessage);
    });
    bc.postMessage({
      type: 'check-another-tab',
      id: session,
    });
    (async () => {
      const createSessionMod = await import('./createSession');
      const isAlreadyAnotherTab = await Promise.race([
        isAnotherTabCheckPromise,
        new Promise(resolve => setTimeout(() => resolve(false), 200)),
      ]);
      if (isAlreadyAnotherTab) {
        bc.removeEventListener('message', onBCMessage);
        setIsAlreadyConnected(true);
        return;
      }
      sessionHelper.current = createSessionMod.default({
        apiKey,
        sessionId: session,
        token: tokenPat,
        onStreamsUpdated: _streams => setStreams([..._streams]),
      });
      sessionHelper.current.session.on(openTokEventHandlers);
    })();

    return () => {
      if (sessionHelper.current?.session) {
        sessionHelper.current.session.off(openTokEventHandlers);
        sessionHelper.current.disconnect();
        sessionHelper.current = null;
        setStreams([]);
        setIsOpenTokConnected(false);
      }
      bc.removeEventListener('message', onBCMessage);
    };
  }, [
    apiKey,
    session,
    tokenPat,
    isVideoSessionStatusValid,
    setIsOpenTokConnected,
    isPathnameValid,
  ]);

  // Effect to connect to websocket
  React.useEffect(() => {
    if (!videoSession) return;
    dispatch(websocketActions.join(`videoSession_${videoSession.id}`));
    const timer = setInterval(() => {
      if (!(centerId && practitionerId)) return;
      if (
        !checkVideoStatusIs(videoSessionStatus, [
          'WAITING',
          'PENDING',
          'STARTED',
        ])
      ) {
        if (timer) {
          clearInterval(timer);
        }
        return;
      }
      dispatch(
        websocketActions.message(
          `videoSessions_${centerId}_${practitionerId}`,
          {
            action: 'ping',
            resource: 'ping',
            emitter: 'web',
            patientId,
            subscriberId: patientId,
            videoSessionId: videoSession.id,
          },
        ),
      );
    }, 3000);
    return () => {
      dispatch(websocketActions.leave(`videoSession_${videoSession.id}`));
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [centerId, dispatch, practitionerId, videoSessionStatus]);

  // Get current video session
  React.useEffect(() => {
    if (!currentUser || isCompleted(currentUser, true) !== true) {
      return;
    }
    if (router.pathname !== '/tlc/session/[id]') {
      dispatch(
        serialActions.serial([
          state => {
            if (!state?.authentication?.item) return;
            return videoSessionsActions.getList({
              statuses: ['WAITING', 'STARTED', 'PENDING'],
              aggregateWith: 'cardHcd',
            });
          },
          state =>
            videoSessionsActions.setItem(state?.videoSessions?.items?.[0]),
        ]),
      );
    }
  }, [router.pathname]);

  // Effect to revert container to their parent element
  React.useEffect(
    () => () => {
      if (container.current)
        parentContainer.current?.appendChild(container.current);
    },
    [],
  );

  React.useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.NEXT_PUBLIC_FAKE_CAMERA !== 'YES_TRUST_ME'
    )
      return;
    return fakeCamera();
  }, []);

  // Handle on change video devices
  React.useEffect(() => {
    if (videoDevices.length === 0) {
      return;
    }
    const defaultVideoDevice = getDefaultDevice(videoDevices);
    if (!defaultVideoDevice) return;
    setVideoSource(defaultVideoDevice.id);
  }, [videoDevices.reduce((result, device) => `${result}|${device.id}`, '')]);

  // Handle on change audio devices
  React.useEffect(() => {
    if (audioDevices.length === 0) {
      return;
    }
    const defaultAudioDevice = getDefaultDevice(audioDevices);
    if (!defaultAudioDevice) return;
    setAudioSource({ deviceId: defaultAudioDevice.id });
  }, [audioDevices.reduce((result, device) => `${result}|${device.id}`, '')]);

  React.useEffect(() => {
    const getListVideoDevices = async () => {
      const detailVideoDevices = await getDetailVideoDevices();
      const _videoDevicesByFacingMode: Record<string, string> = {};
      for (const device of Object.values(detailVideoDevices)) {
        for (const deviceFacingMode of device.facingMode ?? []) {
          _videoDevicesByFacingMode[deviceFacingMode] = device.deviceId;
        }
      }
      setVideoDevicesByFacingMode(_videoDevicesByFacingMode);
      if (
        _videoDevicesByFacingMode.user &&
        _videoDevicesByFacingMode.environment
      ) {
        setSwitchableCamera(true);
      }
      setIsReady(true);
    };

    if (
      router.pathname === '/tlc/session/[id]' &&
      !isEmptyObject(videoDevicesByFacingMode)
    ) {
      setIsReady(false);
      getListVideoDevices();
    } else {
      setIsReady(true);
    }
  }, [router.pathname, videoDevicesByFacingMode]);

  React.useEffect(() => {
    const cleanBackgroundTrack = () => {
      // eslint-disable-next-line no-console
      console.log('Stop backgroundTrack');
      const videoStream = (window as any).backgroundTrack;
      if (videoStream !== undefined) {
        videoStream.getTracks().forEach(track => {
          if (track.readyState === 'live') {
            track.stop();
          }
        });
      }
    };
    // Track when change from tlc validation page
    if (prevPathname === '/tlc/[center-id]/[practitioner-id]') {
      if (router.pathname !== '/tlc/session/[id]') {
        cleanBackgroundTrack();
        return;
      }
      return () => {
        cleanBackgroundTrack();
      };
    }
  }, [router.pathname]);

  const reconnect = React.useCallback(async () => {
    if (sessionHelper.current?.session?.currentState !== 'disconnected') return;
    const createSessionMod = await import('./createSession');
    sessionHelper.current = createSessionMod.default({
      apiKey,
      sessionId: session,
      token: tokenPat as string,
      onStreamsUpdated: _streams => setStreams([..._streams]),
    });
    sessionHelper.current.session.on(openTokEventHandlers);
  }, [apiKey, session, tokenPat]);

  return (
    <OpenTokContext.Provider
      value={{
        session: sessionHelper,
        streams,
        isOpenTokConnected,
        audioDevices,
        videoDevices,
        isProInDocumentViewer,
        audioSource,
        videoSource,
        setAudioSource: _setAudioSource,
        setVideoSource: _setVideoSource,
        publishAudio,
        setPublishAudio,
        publishVideo,
        setPublishVideo,
        showMiniOpenTok,
        showVideoSession,
        container,
        parentContainer,
        facingMode,
        switchFacingMode,
        cameraMirror,
        toggleCameraMirrorMode,
        switchableCamera,
        reconnect,
        isAlreadyConnected,
      }}
    >
      {children}

      {/* DONT CHANGE THE DOM STRUCTURE IN HERE */}
      {showVideoSession && (
        <Portal container={container.current}>
          <OpenTokCommon />
        </Portal>
      )}
      <div style={styles.container}>
        <div ref={setContainer} />
      </div>
      {/* END DON'T CHANGE :D */}
    </OpenTokContext.Provider>
  );
};
export default OpenTokSession;
